import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import {
  Button,
  Checkbox,
  Container,
  Dimmer,
  Divider,
  Grid,
  Header,
  Icon,
  Input,
  Label,
  Loader,
  Table
} from "semantic-ui-react";
import CustomerDetail from './CustomerDetail';
import CONFIG from './utils/config';
import { saveAs } from 'file-saver';
import iconv from 'iconv-lite';
import moment from 'moment';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stats: {
        customers: []
      },
      selectedCustomer: undefined,
      displayActiveAccounts: true,
      displayDemoAccounts: false,
      displayLockedAccounts: false,
      displayTerminatedAccounts: false,
      displayNotes: false,
      loading: false,
      exportLoading: false,
      megaFilter: ""
    };
  }

  componentDidMount() {
    this.setState({loading: true}, () => {
      this.props.rest().get('stats').then((stats) => {
        this.setState({
          stats: Object.assign({}, stats, {
            customers: stats.customers
          })
        });
      }).catch(e => console.log(JSON.stringify(e))).finally(() => {
        this.setState({loading: false});
      });
    });
  }

  openCustomerDetail(customer) {
    return (event) => {
      this.setState({
        selectedCustomer: customer
      });
    };
  }

  getFilenameForExport(extension) {
    const now = moment();
    return `ORANE_ADMIN_EXPORT_${now.format("YYYYMMDD_HH[H]mm[M]ss[S]")}.${extension}`;
  }

  onExportCSV(evt) {
    this.setState({exportLoading: true}, () => {
      const dataToExport = ['ID;Nom;Code;Nb. employés;(actifs);Nb. profils;Nb. murales;(louées);Nb. nomades;(louées);Nb. Applis.;Nb. GPS;Nb. pointages;(bruts);Type;Notes', ...this.state.stats.customers
        .filter(c => this.state.displayActiveAccounts === false ? c.type !== 'ACTIVE' : true)
        .filter(c => this.state.displayDemoAccounts === false ? c.type !== 'DEMO' : true)
        .filter(c => this.state.displayLockedAccounts === false ? c.type !== 'LOCKED' : true)
        .filter(c => this.state.displayTerminatedAccounts === false ? c.type !== 'TERMINATED' : true)
        .map(c => `${c.id};${c.name};${c.code};${c.nbEmployees};${c.nbEmployeesActive};${c.nbProfiles};${c.nbRpiHardware};${c.nbRpiHardwareRent};${c.nbPhoneHardware};${c.nbPhoneHardwareRent};${c.phoneSoftware};${c.nbTraccarHardware};${c.nbPointages};${c.nbRawPointages};${c.type};${c.notes}`)
      ];
      const contentCSV = dataToExport.reduce((acc, line) => `${acc}${line}\r\n`, '');
      const decodedBuffer = iconv.decode(Buffer.from(contentCSV), 'utf8');
      const contentBuffer = iconv.encode(decodedBuffer, 'win1252');
      this.setState({exportLoading: false}, () => {
        saveAs(new Blob([contentBuffer], {type: "text/csv;charset=windows-1252"}), this.getFilenameForExport('csv'));
      });
    });
  }

  render() {
    const onlyActive = (c) => c.type === 'ACTIVE';
    const onlyNotUsingAccount = (c) => c.isUsingHisAccount === false;
    const oraneTotal = Number.parseFloat(this.state.stats.customers.filter(onlyActive).reduce((acc, c) => acc+c.nbEmployeesActive, 0) * CONFIG.prices.ORANE_MONTHLY_PRICE_PER_ACTIVE_EMPLOYEE);
    const rpiTotal = Number.parseFloat(this.state.stats.customers.filter(onlyActive).reduce((acc, c) => acc+c.nbRpiHardwareRent, 0) * CONFIG.prices.RPIHARDWARE_MONTHLY_PRICE);
    const phoneTotal = Number.parseFloat(this.state.stats.customers.filter(onlyActive).reduce((acc, c) => acc+c.nbPhoneHardwareRent, 0) * CONFIG.prices.PHONEHARDWARE_MONTHLY_PRICE);
    const appsTotal = Number.parseFloat(this.state.stats.customers.filter(onlyActive).reduce((acc, c) => acc+c.phoneSoftware, 0) * CONFIG.prices.PHONESOFTWARE_MONTHLY_PRICE);
    const gpsTotal = Number.parseFloat(this.state.stats.customers.filter(onlyActive).reduce((acc, c) => acc+c.nbTraccarHardware, 0) * CONFIG.prices.TRACCARHARDWARE_MONTHLY_PRICE);
    
    const oraneTotalNotUsingTheirAccount =
      Number.parseFloat(this.state.stats.customers.filter(onlyActive).filter(onlyNotUsingAccount).reduce((acc, c) => acc+c.nbEmployeesActive, 0) * CONFIG.prices.ORANE_MONTHLY_PRICE_PER_ACTIVE_EMPLOYEE)
      + Number.parseFloat(this.state.stats.customers.filter(onlyActive).filter(onlyNotUsingAccount).reduce((acc, c) => acc+c.nbRpiHardwareRent, 0) * CONFIG.prices.RPIHARDWARE_MONTHLY_PRICE)
      + Number.parseFloat(this.state.stats.customers.filter(onlyActive).filter(onlyNotUsingAccount).reduce((acc, c) => acc+c.nbPhoneHardwareRent, 0) * CONFIG.prices.PHONEHARDWARE_MONTHLY_PRICE)
      + Number.parseFloat(this.state.stats.customers.filter(onlyActive).filter(onlyNotUsingAccount).reduce((acc, c) => acc+c.nbTraccarHardware, 0) * CONFIG.prices.TRACCARHARDWARE_MONTHLY_PRICE)
    ;

    const globalTotal = oraneTotal + rpiTotal + phoneTotal + appsTotal + gpsTotal;

    const filteredCustomers = this.state.stats.customers
    .filter(c => this.state.displayActiveAccounts === false ? c.type !== 'ACTIVE' : true)
    .filter(c => this.state.displayDemoAccounts === false ? c.type !== 'DEMO' : true)
    .filter(c => this.state.displayLockedAccounts === false ? c.type !== 'LOCKED' : true)
    .filter(c => this.state.displayTerminatedAccounts === false ? c.type !== 'TERMINATED' : true)
    .filter(c => this.state.megaFilter.length > 0 ?
      c.id.toString().toLowerCase().includes(this.state.megaFilter.toLowerCase()) ||
      c.name.toLowerCase().includes(this.state.megaFilter.toLowerCase()) ||
      c.email.toLowerCase().includes(this.state.megaFilter.toLowerCase()) ||
      c.code.toLowerCase().includes(this.state.megaFilter.toLowerCase()) ||
      c.notes.toLowerCase().includes(this.state.megaFilter.toLowerCase()) ||
      Object.keys(c.rpiHardware).map(k => k.toLowerCase()).reduce((acc, x) => `${acc}${x} `, "").includes(this.state.megaFilter.toLowerCase()) ||
      Object.keys(c.phoneHardware).map(k => k.toLowerCase()).reduce((acc, x) => `${acc}${x} `, "").includes(this.state.megaFilter.toLowerCase()) ||
      Object.keys(c.phoneHardware).map(k => (c.phoneHardware[k].imei || "").toLowerCase()).reduce((acc, x) => `${acc}${x} `, "").includes(this.state.megaFilter.toLowerCase()) ||
      Object.keys(c.phoneHardware).map(k => (c.phoneHardware[k].simNumber || "").toLowerCase()).reduce((acc, x) => `${acc}${x} `, "").includes(this.state.megaFilter.toLowerCase())
    : true);

    return (
        <Container className="adminContentClass" textAlign="center">
          {
            <Grid padded>
              <Grid.Row style={{
                backgroundColor: '#545454',
                borderRadius: '10px'
              }}>
                <Grid.Column width={2}>
                  <Icon name='users' size='huge' inverted color='blue' circular />
                </Grid.Column>
                <Grid.Column width={3}></Grid.Column>
                <Grid.Column width={6} verticalAlign="middle" textAlign="center">
                  <Header size="huge" as="h1" inverted>
                    <Header.Content>
                      CA mensuel instantané
                      <Header.Subheader>
                        Une estimation de ce que rapporteront les contrats sur le mois en cours (bases actives uniquement).
                      </Header.Subheader>
                    </Header.Content>
                  </Header>
                </Grid.Column>
                <Grid.Column width={5}></Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Label.Group>
                    <Label size='big' color='teal'>
                      ORANE : {oraneTotal.toFixed(2)} &euro;
                    </Label>
                    <Label size='big' color='yellow'>
                      Pointeuses : {rpiTotal.toFixed(2)} &euro;
                    </Label>
                    <Label size='big' color='green'>
                      Téléphones : {phoneTotal.toFixed(2)} &euro;
                    </Label>
                    <Label size='big' color='pink'>
                      Applications : {appsTotal.toFixed(2)} &euro;
                    </Label>
                    <Label size='big' color='blue'>
                      Boitiers GPS : {gpsTotal.toFixed(2)} &euro;
                    </Label>
                    <Label size='big' color='black'>
                      Total : {globalTotal.toFixed(2)} &euro;
                    </Label>
                    <Label size='big' color='grey'>
                      Total comptes inutilisés : {oraneTotalNotUsingTheirAccount.toFixed(2)} &euro;
                    </Label>
                  </Label.Group>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row style={{
                backgroundColor: '#545454',
                borderRadius: '10px'
              }}>
                <Grid.Column width={2}>
                  <Icon name='users' size='huge' inverted color='blue' circular />
                </Grid.Column>
                <Grid.Column width={3}></Grid.Column>
                <Grid.Column width={6} verticalAlign="middle" textAlign="center">
                  <Header size="huge" as="h1" inverted>
                    <Header.Content>
                      Global
                      <Header.Subheader>
                        Les métriques globales de ORANE (bases actives uniquement).
                      </Header.Subheader>
                    </Header.Content>  
                  </Header>
                </Grid.Column>
                <Grid.Column width={5}></Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Label.Group>
                    <Label size='big' color='blue'>
                      Nombre de clients
                      <Label.Detail>{this.state.stats.customers.filter(onlyActive).length || 0}</Label.Detail>
                    </Label>
                    <Label size='big' color='teal'>
                      Nombre d'employés
                      <Label.Detail>{this.state.stats.customers.filter(onlyActive).reduce((acc, c) => acc+c.nbEmployees, 0)}</Label.Detail>
                    </Label>
                    <Label size='big' color='olive'>
                      Nombre de profils
                      <Label.Detail>{this.state.stats.customers.filter(onlyActive).reduce((acc, c) => acc+c.nbProfiles, 0)}</Label.Detail>
                    </Label>
                    <Label size='big' color='yellow'>
                      Nombre de pointeuses
                      <Label.Detail>{this.state.stats.customers.filter(onlyActive).reduce((acc, c) => acc+c.nbRpiHardware, 0)} ({this.state.stats.customers.filter(onlyActive).reduce((acc, c) => acc+c.nbRpiHardwareRent, 0)})</Label.Detail>
                    </Label>
                    <Label size='big' color='green'>
                      Nombre de téléphones
                      <Label.Detail>{this.state.stats.customers.filter(onlyActive).reduce((acc, c) => acc+c.nbPhoneHardware, 0)} ({this.state.stats.customers.filter(onlyActive).reduce((acc, c) => acc+c.nbPhoneHardwareRent, 0)})</Label.Detail>
                    </Label>
                    <Label size='big' color='pink'>
                      Nombre d'applis.
                      <Label.Detail>{this.state.stats.customers.filter(onlyActive).reduce((acc, c) => acc+c.phoneSoftware, 0)}</Label.Detail>
                    </Label>
                    <Label size='big' color='blue'>
                      Nombre de boitiers GPS
                      <Label.Detail>{this.state.stats.customers.filter(onlyActive).reduce((acc, c) => acc+c.nbTraccarHardware, 0)}</Label.Detail>
                    </Label>
                    <Label size='big' color='purple'>
                      Nombre de pointages
                      <Label.Detail>{this.state.stats.customers.filter(onlyActive).reduce((acc, c) => acc+c.nbPointages, 0)}</Label.Detail>
                    </Label>
                  </Label.Group>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row></Grid.Row>
              <Grid.Row style={{
                backgroundColor: '#545454',
                borderRadius: '10px'
              }}>
                <Grid.Column width={2}>
                  <Icon size='huge' name='user' bordered color='blue' inverted circular />
                </Grid.Column>
                <Grid.Column width={3}></Grid.Column>
                <Grid.Column width={6} textAlign="center" verticalAlign="middle">
                  <Header size="huge" as="h1" inverted>
                    <Header.Content>
                      Par client
                      <Header.Subheader>
                        Cliquez sur une ligne pour plus de détails.
                      </Header.Subheader>
                      <br />
                      <Header.Subheader>
                        <Grid columns={16}>
                          <Grid.Column width={3}>
                            <Checkbox style={{verticalAlign: 'middle'}} toggle checked={this.state.displayActiveAccounts} onChange={() => {
                              this.setState({displayActiveAccounts: !this.state.displayActiveAccounts});
                            }} />
                            <br /><br />
                            <Label>Afficher les bases actives</Label>
                          </Grid.Column>
                          <Grid.Column width={3}>
                            <Checkbox style={{verticalAlign: 'middle'}} toggle checked={this.state.displayDemoAccounts} onChange={() => {
                              this.setState({displayDemoAccounts: !this.state.displayDemoAccounts});
                            }} />
                            <br /><br />
                            <Label>Afficher les bases de démo</Label>
                          </Grid.Column>
                          <Grid.Column width={3}>
                            <Checkbox style={{verticalAlign: 'middle'}} toggle checked={this.state.displayLockedAccounts} onChange={() => {
                              this.setState({displayLockedAccounts: !this.state.displayLockedAccounts});
                            }} />
                            <br /><br />
                            <Label>Afficher les bases verrouillées</Label>
                          </Grid.Column>
                          <Grid.Column width={3}>
                            <Checkbox style={{verticalAlign: 'middle'}} toggle checked={this.state.displayTerminatedAccounts} onChange={() => {
                              this.setState({displayTerminatedAccounts: !this.state.displayTerminatedAccounts});
                            }} />
                            <br /><br />
                            <Label>Afficher les bases résiliées</Label>
                          </Grid.Column>
                          <Grid.Column width={4}>
                            <Checkbox style={{verticalAlign: 'middle'}} toggle checked={this.state.displayNotes} onChange={() => {
                              this.setState({displayNotes: !this.state.displayNotes});
                            }} />
                            <br /><br />
                            <Label>Afficher les notes</Label>
                          </Grid.Column>
                        </Grid>
                      </Header.Subheader>
                    </Header.Content>
                  </Header>
                </Grid.Column>
                <Grid.Column width={4}></Grid.Column>
                <Grid.Column><span data-position="bottom right" data-tooltip="Exporter le tableau en CSV"><Button onClick={this.onExportCSV.bind(this)} loading={this.state.exportLoading} color='green' icon='file excel' /></span></Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Input
                    placeholder='Recherche libre'
                    icon='search'
                    fluid
                    onChange={(evt) => this.setState({megaFilter: evt.target.value})}
                    value={this.state.megaFilter}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column style={{textAlign: 'left'}} width={2}>
                  <Label size='large'>{filteredCustomers.length} ligne(s) trouvée(s)</Label>
                </Grid.Column>
                <Grid.Column width={14}></Grid.Column>
              </Grid.Row>
              <Grid.Row style={{minHeight: '30vh'}}>
              {
                  this.state.loading === true ?
                  <Dimmer inverted active>
                    <Loader inverted />
                  </Dimmer>
                  :
                    this.state.selectedCustomer !== undefined ?
                    <CustomerDetail close={this.openCustomerDetail(undefined).bind(this)} customer={this.state.selectedCustomer} />
                    :
                    <Table textAlign='center' selectable>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Nom</Table.HeaderCell>
                          <Table.HeaderCell>Code</Table.HeaderCell>
                          <Table.HeaderCell>Nombre d'employés (actifs)</Table.HeaderCell>
                          <Table.HeaderCell>Nombre de profils</Table.HeaderCell>
                          <Table.HeaderCell>Nombre de pointeuses (louées)</Table.HeaderCell>
                          <Table.HeaderCell>Nombre de téléphones (loués)</Table.HeaderCell>
                          <Table.HeaderCell>Nombre d'applis.</Table.HeaderCell>
                          <Table.HeaderCell>Nombre de boitiers GPS</Table.HeaderCell>
                          <Table.HeaderCell>Nombre de pointages (bruts)</Table.HeaderCell>
                          <Table.HeaderCell>Type</Table.HeaderCell>
                          <Table.HeaderCell>Utilisé</Table.HeaderCell>
                          <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {
                          filteredCustomers
                          .map(c =>
                            [
                              <Table.Row key={`stats_${c.id}`}>
                                <Table.Cell>{c.name}</Table.Cell>
                                <Table.Cell>{c.code}</Table.Cell>
                                <Table.Cell><Label key={`${c.id}_nbE`} circular color='teal'>{c.nbEmployees}</Label><Label basic key={`${c.id}_nbEA`} circular color='teal'>{c.nbEmployeesActive}</Label></Table.Cell>
                                <Table.Cell><Label circular color='olive'>{c.nbProfiles}</Label></Table.Cell>
                                <Table.Cell><Label key={`${c.id}_nbRPIH`} circular color='yellow'>{c.nbRpiHardware}</Label><Label key={`${c.id}_nbRPIH_RENT`} basic circular color='yellow'>{c.nbRpiHardwareRent}</Label></Table.Cell>
                                <Table.Cell><Label key={`${c.id}_nbPHONEH`} circular color='green'>{c.nbPhoneHardware}</Label><Label key={`${c.id}_nbPHONEH_RENT`} basic circular color='green'>{c.nbPhoneHardwareRent}</Label></Table.Cell>
                                <Table.Cell><Label circular color='pink'>{c.phoneSoftware}</Label></Table.Cell>
                                <Table.Cell><Label circular color='blue'>{c.nbTraccarHardware}</Label></Table.Cell>
                                <Table.Cell><Label key={`${c.id}_nbP`} circular color='purple'>{c.nbPointages}</Label><Label key={`${c.id}_nbPR`} basic circular color='purple'>{c.nbRawPointages}</Label></Table.Cell>
                                <Table.Cell>
                                  <span
                                    data-position="bottom right"
                                    data-tooltip={c.type === 'ACTIVE' ? 'Actif' : c.type === 'DEMO' ? 'Démo' : c.type === 'LOCKED' ? 'Verrouillé' : c.type === 'TERMINATED' ? 'Résilié' : 'Inconnu'}>
                                    <Icon
                                      name={c.type === 'ACTIVE' ? 'check' : c.type === 'DEMO' ? 'cloud' : c.type === 'LOCKED' ? 'lock' : c.type === 'TERMINATED' ? 'delete' : 'question'}
                                      color={c.type === 'ACTIVE' ? 'green' : c.type === 'DEMO' ? 'blue' : c.type === 'LOCKED' ? 'red' : c.type === 'TERMINATED' ? 'red' : 'orange'}
                                      />
                                  </span>
                                </Table.Cell>
                                <Table.Cell>
                                  <span
                                    data-position="bottom right"
                                    data-tooltip={`${c.isUsingHisAccount ? "Oui" : "Non"}${c.lastPointageTS !== null && c.lastPointageTS !== undefined ? ` (dernier pointage : ${moment.unix(c.lastPointageTS).format("DD/MM/YYYY HH:mm")})` : ''}`}>
                                    <Icon
                                      name={c.isUsingHisAccount ? 'check' : 'delete'}
                                      color={c.isUsingHisAccount ? 'green' : 'orange'}
                                      />
                                  </span>
                                </Table.Cell>
                                <Table.Cell><span data-position="bottom right" data-tooltip="Afficher les historiques de données"><Button icon onClick={this.openCustomerDetail(c).bind(this)} ><Icon name='chart bar' /></Button></span></Table.Cell>
                              </Table.Row>,
                              this.state.displayNotes ?
                              <Table.Row key={`notes_${c.id}`}>
                                <Table.Cell style={{textDecoration: 'underline'}}>Notes</Table.Cell>
                                <Table.Cell textAlign='left' colSpan='9'>{c.notes}</Table.Cell>
                                <Table.Cell></Table.Cell>
                              </Table.Row>
                              : null
                            ].flat().filter(x => x !== null)
                          )
                        }
                      </Table.Body>
                    </Table>
              }
              </Grid.Row>
            </Grid>
          }
        </Container>
    );
  }
}

export default Dashboard;
