import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import {
  Button,
  Container,
  Dimmer,
  Dropdown,
  Form,
  Grid,
  Header,
  Icon,
  Input,
  Label,
  Loader,
  Message,
  Modal,
  Popup,
  Table,
  TextArea
} from "semantic-ui-react";
import resolvePath from 'object-resolve-path';
import crypto from 'crypto';
import randomstring from 'randomstring';

const sha256 = (str) => crypto.createHash('sha256').update(str).digest('hex');
const randomToken = () => sha256(randomstring.generate(30));


class RpiHardwares extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rpiHardwares: [],
      vpnClientList: [],
      vpnCertList: [],
      sortState: {
        column: 'name',
        direction: 'ascending'
      },
      selectedRpiHFilter: [],
      selectedSerialNumberFilter: undefined,
      selectedBoardFilter: [],
      selectedRfidFilter: [],
      selectedDataStickFilter: [],
      selectedStatusFilter: [],
      selectedCommercialStatusFilter: [],
      selectedSimNumberFilter: [],
      error: undefined,
      editingRpiHardware: undefined,
      loading: true,
      loadingVPNConfig: false,
      loadingDmesg: false,
      loadingPointages: false,
      loadingCmd: false,
      cmdPopupOpened: false,
      cmd: "",
      megaFilter: "",

      cmdout: "",
      cmdoutModalOpened: false
    };
  }

  componentDidMount() {
    this.getRpiHardwares();
  }

  getRpiHardwares() {
    return Promise.all([
      this.props.rest().get('rpihardwares'),
      this.props.rest().get('openvpn/clientList'),
      this.props.rest().get('openvpn/certList')
    ]).then(([rpiHardwares,vpnClientList,vpnCertList]) => {
      this.setState({
        rpiHardwares: rpiHardwares.sort((x,y) => x.name < y.name ? -1 : 1),
        vpnClientList,
        vpnCertList
      });
    }).catch((e) => {
      this.manageErrors(e);
    }).finally(() => {
      this.setState({loading: false});
    });
  }

  manageErrors(err) {
    if(err !== undefined) {
      this.setState({
        error: err.entity || err
      });
      setTimeout(() => {
        this.setState({
          error: undefined
        });
      }, 10000);
    }
  }

  onClickOnAddRpiHardware(event) {
    const selectedSerial = this.state.selectedSerialNumberFilter;
    if(selectedSerial !== undefined && selectedSerial !== null && selectedSerial !== '') {
      const rpiH = {
        name: selectedSerial,
        token: randomToken(),
        board: 'RPI3B',
        dataStick: 'EC25',
        rfid: '125KHZ',
        simNumber: '',
        misc: '',
        status: 'IN_STOCK',
        commercialStatus: 'RENT'
      };
      this.props.rest().post('rpihardwares', rpiH).then(result => {
        this.setState({
          editingRpiHardware: undefined,
          rpiHardwares: [Object.assign(rpiH, {id: result.id}), ...this.state.rpiHardwares.filter(x => x.name !== rpiH.name)]
        });
      })
      .catch(e => this.manageErrors(e));
    }
  }

  onClickOnUpdRpiHardware(rpiH) {
    return (event) => {
      this.props.rest().put('rpihardwares', rpiH).then(rpiH => {
        this.setState({
          editingRpiHardware: undefined,
          rpiHardwares: [...this.state.rpiHardwares.filter(x => x.id !== rpiH.id && x.name !== rpiH.name), rpiH].sort((d1,d2) => resolvePath(d1, this.state.sortState.column) < resolvePath(d2, this.state.sortState.column) ? -1 : 1)
        });
      }).catch(e => this.manageErrors(e));
    };
  }

  onChangeRpiHardwareProp(propName) {
    return (event, data) => {
      this.setState({
        editingRpiHardware: Object.assign({}, this.state.editingRpiHardware, {
          [propName]: event.target.value
        })
      });
    }
  }

  onChangeRpiHardwarePropEnum(propName) {
    return (e,data) => {
      this.setState({
        editingRpiHardware: Object.assign({}, this.state.editingRpiHardware, {
          [propName]: data.value
        })
      });
    }
  }

  handleSort(clickedColumn) {
    return () => {
      const { column, direction } = this.state.sortState;
      const data = this.state.rpiHardwares;

      if (column !== clickedColumn) {
        this.setState({
          sortState: Object.assign(this.state.sortState, {
            column: clickedColumn,
            direction: 'ascending'
          }),
          rpiHardwares: data.sort((d1,d2) => resolvePath(d1, clickedColumn) < resolvePath(d2, clickedColumn) ? -1 : 1)
        });
        return;
      }

      this.setState({
        sortState: Object.assign(this.state.sortState, {
          data: data.reverse(),
          direction: direction === 'ascending' ? 'descending' : 'ascending',
          column
        })
      });
    }
  }

  onChangeRpiHardwareFilter(filterProp) {
    return (e,data) => {
      this.setState({
        [filterProp]: data.value
      });
    }
  }

  onClickOnCopyConfig(rpiH) {
    return (evt) => {
      this.setState({loadingVPNConfig: rpiH.name}, () => {
        this.props.rest().get(`openvpn/config/${rpiH.name}`)
          .then(vpnConfig => {
            this.setState({
              rpiHardwares: this.state.rpiHardwares.map(x => x.name === rpiH.name ? Object.assign({}, rpiH, {config: Object.assign({}, rpiH.config, vpnConfig)}) : x)
            }, () => {
              const toCopyObj = (this.state.rpiHardwares.find(x => x.name === rpiH.name) || {}).config || {};
              this.onCopy(JSON.stringify(toCopyObj, null, 2))(evt);
            });
          }).catch((e) => {
            this.manageErrors(e);
          }).finally(() => {
            this.setState({loadingVPNConfig: false});
          });
        });
    };
  }

  onClickOnDmesg(rpiH) {
    return (evt) => {
      this.setState({cmd: "sudo dmesg", loadingPointages: rpiH.name}, () => {
        this.requestExecCmd(rpiH.name, this.state.cmd).finally(() => {
          this.setState({loadingPointages: false});
        });
      });
    };
  }

  onClickOnGetPointages(rpiH) {
    return (evt) => {
      this.setState({cmd: "cat /home/alarm/point/server/data/pointages.json", loadingPointages: rpiH.name}, () => {
        this.requestExecCmd(rpiH.name, this.state.cmd).finally(() => {
          this.setState({loadingPointages: false});
        });
      });
    };
  }

  onClickOnCmd(rpiH) {
    return (evt) => {
      this.setState({loadingCmd: rpiH.name}, () => {
        this.requestExecCmd(rpiH.name, this.state.cmd).finally(() => {
          this.setState({loadingCmd: false, cmdPopupOpened: false});
        });
      });
    };
  }

  requestExecCmd(rpiHName, cmd) {
    return this.props.rest().post(`openvpn/cmd/${rpiHName}`, {cmd})
      .then((result) => {
        const {stdout} = result;
        this.setState({
          cmdout: stdout,
          cmdoutModalOpened: rpiHName
        });
      }).catch((e) => {
        this.manageErrors(e);
      });
  }

  onCopy(content) {
    return (evt) => {
      this.writeToClipboardIfPossible(content || "");
    };
  }

  writeToClipboardIfPossible(data) {
    return navigator.permissions.query({name: "clipboard-write"}).then((result) => {
      if (result.state === "granted" || result.state === "prompt") {
        return navigator.clipboard.writeText(data);
      } else {
        return Promise.reject("Clipboard : Permission denied");
      }
    }).catch(err => console.log(`Err while writing clipboard : ${err}`));
  }

  onChangeMegaFilter(evt) {
    this.setState({megaFilter: evt.target.value});
  }

  handleCmdChange(evt) {
    this.setState({cmd: evt.target.value});
  }

  render() {
    const statuses = [
      {
        key: "IN_STOCK",
        text: "En stock",
        value: "IN_STOCK"
      },
      {
        key: "DISMANTLED",
        text: "Démantelée",
        value: "DISMANTLED"
      },
      {
        key: "SAV",
        text: "En SAV",
        value: "SAV"
      },
      {
        key: "OTHER",
        text: "Autre",
        value: "OTHER"
      }
    ];

    const commercialStatuses = [
      {
        key: "RENT",
        text: "Loué",
        value: "RENT"
      },
      {
        key: "SOLD",
        text: "Vendu",
        value: "SOLD"
      }
    ];

    const dataSticks = [
      {
        key: "HUAWEI",
        text: "HUAWEI E172",
        value: "HUAWEI"
      },
      {
        key: "EC25",
        text: "EC25",
        value: "EC25"
      },
      {
        key: "NONE",
        text: "N/A",
        value: "NONE"
      }
    ];

    const boards = [
      {
        key: "RPI3B",
        text: "3B",
        value: "RPI3B"
      },
      {
        key: "RPI3BP",
        text: "3B+",
        value: "RPI3BP"
      }
    ];

    const rfids = [
      {
        key: "125KHZ",
        text: "125KHZ",
        value: "125KHZ"
      },
      {
        key: "13MHZ",
        text: "13MHZ",
        value: "13MHZ"
      }
    ];

    const filterRpiHOpts = this.state.rpiHardwares.map((e, idx) => ({
      key: e.id,
      text: e.name,
      value: e.id
    }));

    const filterSimNOpts = this.state.rpiHardwares.filter(x => x.simNumber !== null && x.simNumber !== undefined && x.simNumber.length > 0)
    .map((x, idx) => ({
      key: x.id,
      text: x.simNumber,
      value: x.id
    }));

    const getCmdPopup = (rpiH) => {
      return <Popup
        content={
          <Input onChange={this.handleCmdChange.bind(this)} value={this.state.cmd} fluid placeholder='ls -al' action={
            <Button.Group>
              <Button color='red' loading={this.state.loadingCmd === rpiH.name} onClick={this.onClickOnCmd(rpiH).bind(this)}><Icon name='play' /></Button>,
              <Button icon='close' style={{float: 'right'}} onClick={() => this.setState({cmdPopupOpened: false})} />
            </Button.Group>
          } />
        }
        open={this.state.cmdPopupOpened === rpiH.name}
        flowing
        position='bottom right'
        header={<span>Entrez votre commande dans le champ ci-dessous puis appuyez sur le bouton rouge pour exécuter ou sur la croix pour fermer</span>}
        on='click'
        pinned
        trigger={
          <span data-tooltip="Exécuter une commande arbitraire" data-position="bottom right"><Button color='red' disabled={this.state.vpnClientList.includes(rpiH.name) === false} onClick={() => this.setState({cmdPopupOpened: this.state.cmdPopupOpened === rpiH.name ? false : rpiH.name})}><Icon name='keyboard outline' /></Button></span>
        }
      />;
    }

    const filteredRpiHardwares = this.state.rpiHardwares
    .filter(x => this.state.megaFilter.length > 0 ?
      x.name.toLowerCase().includes(this.state.megaFilter.toLowerCase()) ||
      x.misc.toLowerCase().includes(this.state.megaFilter.toLowerCase()) ||
      x.simNumber.toLowerCase().includes(this.state.megaFilter.toLowerCase())
    : true)
    .filter(x => this.state.selectedRpiHFilter.length > 0 ? this.state.selectedRpiHFilter.includes(x.id) : true)
    .filter(x => this.state.selectedBoardFilter.length > 0 ? this.state.selectedBoardFilter.includes(x.board) : true)
    .filter(x => this.state.selectedRfidFilter.length > 0 ? this.state.selectedRfidFilter.includes(x.rfid) : true)
    .filter(x => this.state.selectedDataStickFilter.length > 0 ? this.state.selectedDataStickFilter.includes(x.dataStick) : true)
    .filter(x => this.state.selectedStatusFilter.length > 0 ? this.state.selectedStatusFilter.includes(x.status) : true)
    .filter(x => this.state.selectedCommercialStatusFilter.length > 0 ? this.state.selectedCommercialStatusFilter.includes(x.commercialStatus) : true)
    .filter(x => this.state.selectedSimNumberFilter.length > 0 ? this.state.selectedSimNumberFilter.includes(x.id) : true);

    return (
        <Container className="adminContentClass" textAlign="center">
            <Modal size='large' open={this.state.cmdoutModalOpened !== false}>
              <Modal.Header icon='list alternate outline' content={`Résultat de la commande ${this.state.cmd} pour ${this.state.cmdoutModalOpened}`} />
              <Modal.Content style={{minHeight: '50vh'}} scrolling>
                <Form>
                  <TextArea style={{minHeight: '50vh'}} placeholder="Oups...la sortie est vide..." value={this.state.cmdout} />
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button basic color='red' onClick={() => this.setState({cmdoutModalOpened: false})}>
                  <Icon name='close' /> Fermer
                </Button>
                <Button color='blue' onClick={this.onCopy(this.state.cmdout).bind(this)}>
                  <Icon name='copy outline' /> Copier dans le presse-papiers
                </Button>
              </Modal.Actions>
            </Modal>
            <Grid padded>
              <Grid.Row>
                {
                  this.state.error !== undefined ?
                    <Message negative content={this.state.error} />
                    : ""
                }
              </Grid.Row>
              <Grid.Row style={{
                  backgroundColor: '#545454',
                  borderRadius: '10px'
                }}>
                <Grid.Column width={2}>
                  <Icon name='hdd' size='huge' inverted color='blue' circular />
                </Grid.Column>
                <Grid.Column width={1}></Grid.Column>
                <Grid.Column width={10} verticalAlign="middle" textAlign="center">
                  <Header size="huge" as="h1" inverted>
                    <Header.Content>
                      Liste des badgeuses murales
                      <Header.Subheader>
                        Retrouvez ici la liste des badgeuses murales
                      </Header.Subheader>
                    </Header.Content>
                  </Header>
                </Grid.Column>
                <Grid.Column width={2}></Grid.Column>
                <Grid.Column width={1} verticalAlign="middle">
                  <Popup
                    position='left center'
                    wide='very'
                    content={
                      <span>
                        <Dropdown
                          placeholder='Numéro de série'
                          search
                          selection
                          clearable
                          onChange={(evt, data) => this.setState({selectedSerialNumberFilter: data.value})}
                          value={this.state.selectedSerialNumberFilter}
                          options={this.state.vpnCertList.filter(x => this.state.rpiHardwares.map(rh => rh.name).includes(x.commonName.slice(4)) === false).map(x => ({key: x.commonName.slice(4), text: x.commonName.slice(4), value: x.commonName.slice(4)}))}
                        />
                        <Button icon color='green' disabled={this.state.selectedSerialNumberFilter === undefined} onClick={this.onClickOnAddRpiHardware.bind(this)}>
                          <Icon name='play' />
                        </Button>
                      </span>
                    }
                    on='click'
                    pinned
                    trigger={
                      <Button data-tooltip="Ajouter une badgeuse murale" data-position="bottom right" icon color='green'>
                        <Icon name='plus' />
                      </Button>
                    }
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Input
                    placeholder='Recherche libre (Numéro de série / Numéro de sim / Notes)'
                    icon='search'
                    fluid
                    onChange={this.onChangeMegaFilter.bind(this)}
                    value={this.state.megaFilter}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={5}>
                  <Dropdown
                    placeholder='Toutes les badgeuses murales'
                    search
                    fluid
                    selection
                    multiple
                    clearable
                    onChange={this.onChangeRpiHardwareFilter('selectedRpiHFilter').bind(this)}
                    value={this.state.selectedRpiHFilter}
                    options={filterRpiHOpts}
                  />
                </Grid.Column>
                <Grid.Column width={5}>
                  <Dropdown
                    placeholder='Tous les numéros de SIM'
                    search
                    fluid
                    selection
                    multiple
                    clearable
                    onChange={this.onChangeRpiHardwareFilter('selectedSimNumberFilter').bind(this)}
                    value={this.state.selectedSimNumberFilter}
                    options={filterSimNOpts}
                  />
                </Grid.Column>
                <Grid.Column width={3}>
                  <Dropdown
                    placeholder='Tous les statuts'
                    search
                    fluid
                    selection
                    multiple
                    clearable
                    onChange={this.onChangeRpiHardwareFilter('selectedStatusFilter').bind(this)}
                    value={this.state.selectedStatusFilter}
                    options={statuses}
                  />
                </Grid.Column>
                <Grid.Column width={3}>
                  <Dropdown
                    placeholder='Tous les statuts commerciaux'
                    search
                    fluid
                    selection
                    multiple
                    clearable
                    onChange={this.onChangeRpiHardwareFilter('selectedCommercialStatusFilter').bind(this)}
                    value={this.state.selectedCommercialStatusFilter}
                    options={commercialStatuses}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={6}>
                  <Dropdown
                    placeholder='Toutes les cartes'
                    search
                    fluid
                    selection
                    multiple
                    clearable
                    onChange={this.onChangeRpiHardwareFilter('selectedBoardFilter').bind(this)}
                    value={this.state.selectedBoardFilter}
                    options={boards}
                  />
                </Grid.Column>
                <Grid.Column width={5}>
                  <Dropdown
                    placeholder='Tous les RFID'
                    search
                    fluid
                    selection
                    multiple
                    clearable
                    onChange={this.onChangeRpiHardwareFilter('selectedRfidFilter').bind(this)}
                    value={this.state.selectedRfidFilter}
                    options={rfids}
                  />
                </Grid.Column>
                <Grid.Column width={5}>
                  <Dropdown
                    placeholder='Toutes les clés 3G'
                    search
                    fluid
                    selection
                    multiple
                    clearable
                    onChange={this.onChangeRpiHardwareFilter('selectedDataStickFilter').bind(this)}
                    value={this.state.selectedDataStickFilter}
                    options={dataSticks}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column style={{textAlign: 'left'}} width={2}>
                  <Label size='large'>{filteredRpiHardwares.length} ligne(s) trouvée(s)</Label>
                </Grid.Column>
                <Grid.Column width={14}></Grid.Column>
              </Grid.Row>
              <Grid.Row style={{minHeight: '30vh'}}>
                {
                  this.state.loading === true ?
                  <Dimmer inverted active>
                    <Loader inverted />
                  </Dimmer>                
                  :
                  <Table striped selectable sortable>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell
                        sorted={this.state.sortState.column === 'name' ? this.state.sortState.direction : null}
                        onClick={this.handleSort('name').bind(this)}>
                          Numéro de série
                        </Table.HeaderCell>
                        <Table.HeaderCell>Token</Table.HeaderCell>
                        <Table.HeaderCell
                        sorted={this.state.sortState.column === 'board' ? this.state.sortState.direction : null}
                        onClick={this.handleSort('board').bind(this)}>
                          Carte
                        </Table.HeaderCell>
                        <Table.HeaderCell
                        sorted={this.state.sortState.column === 'rfid' ? this.state.sortState.direction : null}
                        onClick={this.handleSort('rfid').bind(this)}>
                          RFID
                        </Table.HeaderCell>
                        <Table.HeaderCell
                        sorted={this.state.sortState.column === 'dataStick' ? this.state.sortState.direction : null}
                        onClick={this.handleSort('dataStick').bind(this)}>
                          Clé 3G
                        </Table.HeaderCell>
                        <Table.HeaderCell>Numéro de sim</Table.HeaderCell>
                        <Table.HeaderCell>Notes</Table.HeaderCell>
                        <Table.HeaderCell
                        sorted={this.state.sortState.column === 'status' ? this.state.sortState.direction : null}
                        onClick={this.handleSort('status').bind(this)}>
                          Statut
                        </Table.HeaderCell>
                        <Table.HeaderCell
                        sorted={this.state.sortState.column === 'commercialStatus' ? this.state.sortState.direction : null}
                        onClick={this.handleSort('commercialStatus').bind(this)}>
                          Statut commercial
                        </Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {
                        filteredRpiHardwares
                        .map((rpiH, idx) => this.state.editingRpiHardware !== undefined && this.state.editingRpiHardware.id === rpiH.id ? (
                          <Table.Row key={`details_rpihardware_${idx}`}>
                            <Table.Cell width={1}>
                              {/*<Input fluid disabled={rpiH.status === 'PRODUCTION'} onChange={this.onChangeRpiHardwareProp('name').bind(this)} value={this.state.editingRpiHardware.name} />*/}
                              <Label color={this.state.vpnClientList.includes(rpiH.name) ? 'green' : 'red'}>{this.state.editingRpiHardware.name}</Label>
                            </Table.Cell>
                            <Table.Cell width={2}>
                              <Input fluid disabled={rpiH.status === 'PRODUCTION'} onChange={this.onChangeRpiHardwareProp('token').bind(this)} value={this.state.editingRpiHardware.token} />
                            </Table.Cell>
                            <Table.Cell width={1}>
                              <Dropdown
                                placeholder='Carte...'
                                search
                                fluid
                                selection
                                onChange={this.onChangeRpiHardwarePropEnum('board').bind(this)}
                                value={this.state.editingRpiHardware.board}
                                options={boards}
                              />
                            </Table.Cell>
                            <Table.Cell width={1}>
                              <Dropdown
                                placeholder='RFID...'
                                search
                                fluid
                                selection
                                onChange={this.onChangeRpiHardwarePropEnum('rfid').bind(this)}
                                value={this.state.editingRpiHardware.rfid}
                                options={rfids}
                              />
                            </Table.Cell>
                            <Table.Cell width={2}>
                              <Dropdown
                                placeholder='Clé 3G...'
                                search
                                fluid
                                selection
                                onChange={this.onChangeRpiHardwarePropEnum('dataStick').bind(this)}
                                value={this.state.editingRpiHardware.dataStick}
                                options={dataSticks}
                              />
                            </Table.Cell>
                            <Table.Cell width={2}>
                              <Input fluid onChange={this.onChangeRpiHardwareProp('simNumber').bind(this)} value={this.state.editingRpiHardware.simNumber} />
                            </Table.Cell>
                            <Table.Cell width={3}>
                              <Input fluid onChange={this.onChangeRpiHardwareProp('misc').bind(this)} value={this.state.editingRpiHardware.misc} />
                            </Table.Cell>
                            <Table.Cell width={1}>
                              {
                                rpiH.status === 'PRODUCTION' ? 
                                  <Dropdown
                                    fluid
                                    selection
                                    disabled
                                    value='PRODUCTION'
                                    options={[{
                                      key: 'PRODUCTION',
                                      text: 'En prod',
                                      value: 'PRODUCTION'
                                    }]}
                                  /> :
                                  <Dropdown
                                    placeholder='Statut...'
                                    search
                                    fluid
                                    selection
                                    onChange={this.onChangeRpiHardwarePropEnum('status').bind(this)}
                                    value={this.state.editingRpiHardware.status}
                                    options={statuses}
                                  />
                              }
                            </Table.Cell>
                            <Table.Cell>
                              <Dropdown
                                placeholder='Vendu'
                                search
                                fluid
                                selection
                                onChange={this.onChangeRpiHardwarePropEnum('commercialStatus').bind(this)}
                                value={this.state.editingRpiHardware.commercialStatus}
                                options={commercialStatuses}
                              />
                            </Table.Cell>
                            <Table.Cell width={3}>
                              <Button.Group>
                                <Button color='green' data-tooltip="Enregistrer les modifications" data-position="bottom right" onClick={this.onClickOnUpdRpiHardware(this.state.editingRpiHardware).bind(this)}><Icon name='check' /></Button>
                                <span data-tooltip="Copier la config de prod dans le presse-papiers" data-position="bottom right"><Button color='blue' loading={this.state.loadingVPNConfig === rpiH.name} onClick={this.onClickOnCopyConfig(rpiH).bind(this)}><Icon name='copy outline' /></Button></span>
                                <span data-tooltip="Voir le fichier des pointages" data-position="bottom right"><Button color='teal' loading={this.state.loadingPointages === rpiH.name} disabled={this.state.vpnClientList.includes(rpiH.name) === false}onClick={this.onClickOnGetPointages(rpiH).bind(this)}><Icon name='clock outline' /></Button></span>
                                <span data-tooltip="Voir le dmesg" data-position="bottom right"><Button color='orange' loading={this.state.loadingDmesg === rpiH.name} disabled={this.state.vpnClientList.includes(rpiH.name) === false} onClick={this.onClickOnDmesg(rpiH).bind(this)}><Icon name='list alternate outline' /></Button></span>
                                {getCmdPopup(rpiH)}
                              </Button.Group>
                            </Table.Cell>
                          </Table.Row>
                        ) : (
                          <Table.Row onClick={() => this.setState({editingRpiHardware: rpiH})} key={`details_rpihardware_${idx}`}>
                            <Table.Cell width={1}><Label color={this.state.vpnClientList.includes(rpiH.name) ? 'green' : 'red'}>{rpiH.name}</Label></Table.Cell>
                            <Table.Cell width={2}>{rpiH.token}</Table.Cell>
                            <Table.Cell width={1}>{boards.map(x => x.value).includes(rpiH.board) ? boards.find(x => x.value === rpiH.board).text : rpiH.board}</Table.Cell>
                            <Table.Cell width={1}>{rfids.map(x => x.value).includes(rpiH.rfid) ? rfids.find(x => x.value === rpiH.rfid).text : rpiH.rfid}</Table.Cell>
                            <Table.Cell width={2}>{dataSticks.map(x => x.value).includes(rpiH.dataStick) ? dataSticks.find(x => x.value === rpiH.dataStick).text : rpiH.dataStick}</Table.Cell>
                            <Table.Cell width={2}>{rpiH.simNumber}</Table.Cell>
                            <Table.Cell width={3}>{rpiH.misc}</Table.Cell>
                            <Table.Cell width={1}>{rpiH.status === 'PRODUCTION' ? 'En prod' : statuses.map(x => x.value).includes(rpiH.status) ? statuses.find(x => x.value === rpiH.status).text : rpiH.status}</Table.Cell>
                            <Table.Cell>{commercialStatuses.map(x => x.value).includes(rpiH.commercialStatus) ? commercialStatuses.find(x => x.value === rpiH.commercialStatus).text : rpiH.commercialStatus}</Table.Cell>
                            <Table.Cell width={3}>
                              <Button.Group>
                                <Button color='green'><Icon name='check' disabled /></Button>
                                <span data-tooltip="Copier la config de prod dans le presse-papiers" data-position="bottom right"><Button color='blue' loading={this.state.loadingVPNConfig === rpiH.name} onClick={this.onClickOnCopyConfig(rpiH).bind(this)}><Icon name='copy outline' /></Button></span>
                                <span data-tooltip="Voir le fichier des pointages" data-position="bottom right"><Button color='teal' loading={this.state.loadingPointages === rpiH.name} disabled={this.state.vpnClientList.includes(rpiH.name) === false} onClick={this.onClickOnGetPointages(rpiH).bind(this)}><Icon name='clock outline' /></Button></span>
                                <span data-tooltip="Voir le dmesg" data-position="bottom right"><Button color='orange' loading={this.state.loadingDmesg === rpiH.name} disabled={this.state.vpnClientList.includes(rpiH.name) === false} onClick={this.onClickOnDmesg(rpiH).bind(this)}><Icon name='list alternate outline' /></Button></span>
                                {getCmdPopup(rpiH)}
                            </Button.Group>
                            </Table.Cell>
                          </Table.Row>
                        ))
                      }
                    </Table.Body>
                  </Table>
                }
              </Grid.Row>
            </Grid>
        </Container>
    );
  }
}

export default RpiHardwares;
