import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import {
  Button,
  Container,
  Checkbox,
  Grid,
  Header,
  Divider,
  Icon,
  Input,
  Label,
  Table,
  Popup
} from "semantic-ui-react";
import Plot from 'react-plotly.js';
import moment from './utils/momentfr';

const AUX_MODULES_NAMES = {
  "emailReports": "Rapports email auto."
};

class CustomerDetail extends React.Component {
  render() {
    if(this.props.customer.history !== undefined) {
      const {employees, profiles, pointages, auxModules, rpiHardwareOnline, nightShiftEnabled} = this.props.customer.history;
      const rpiHardware = Object.keys(this.props.customer.rpiHardware || {});
      return (
          <Grid padded style={{width: '100%'}}>
            <Grid.Row></Grid.Row>
            <Grid.Row style={{
                  backgroundColor: '#646464',
                  borderRadius: '10px'
              }}>
              <Grid.Column width={1}>
                <Button onClick={this.props.close} icon='backward' data-position="bottom left" data-tooltip="Revenir en arrière" color='red' style={{float: 'left'}} />
              </Grid.Column>
              <Grid.Column width={4}></Grid.Column>
              <Grid.Column width={6} verticalAlign="middle" textAlign="center">
                  <Header size="large" as="h2" inverted>
                    <Header.Content>
                      {`Détails du client ${this.props.customer.name}`}
                    </Header.Content>  
                  </Header>
              </Grid.Column>
              <Grid.Column width={5}></Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={4} textAlign="center" verticalAlign="middle">
                <Label color='teal' size='big'>Nombre d'employés</Label>
              </Grid.Column>
              <Grid.Column width={12} textAlign="center" verticalAlign="middle">
                <Plot
                  data={[
                    {
                      x: employees.map(e => moment.unix(e.timestamp).format("YYYY-MM-DD HH:mm:ss")),
                      y: employees.map(e => e.nbEmployees),
                      type: 'scatter',
                      mode: 'lines+markers',
                      marker: {
                        color: 'teal',
                        size: 8
                      },
                      line: {
                        color: 'teal',
                        width: 2
                      },
                      name: 'Total'
                    },
                    {
                      x: employees.map(e => moment.unix(e.timestamp).format("YYYY-MM-DD HH:mm:ss")),
                      y: employees.map(e => e.nbEmployeesActive),
                      type: 'scatter',
                      mode: 'lines+markers',
                      marker: {
                        color: 'blue',
                        size: 8
                      },
                      line: {
                        color: 'blue',
                        width: 2,
                        dash: 'dashdot'
                      },
                      name: 'Actifs'
                    }
                  ]}
                  layout={ {
                    width: '100%',
                    title: '',
                    xaxis: {
                      title: '',
                      type: 'date',
                      autorange: true,
                      rangeslider: {range: [
                        moment.unix(Math.min(...employees.map(e => e.timestamp))).format("YYYY-MM-DD"),
                        moment().format("YYYY-MM-DD")
                      ]},
                    },
                    yaxis: {
                      title: ''
                    }
                  } }
                  config={{displayModeBar: false}}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={4} textAlign="center" verticalAlign="middle">
                <Label color='olive' size='big'>Nombre de profils</Label>
              </Grid.Column>
              <Grid.Column width={12} textAlign="center" verticalAlign="middle">
                <Plot
                  data={[
                    {
                      x: profiles.map(p => moment.unix(p.timestamp).format("YYYY-MM-DD HH:mm:ss")),
                      y: profiles.map(p => p.nbProfiles),
                      type: 'scatter',
                      mode: 'lines+markers',
                      marker: {
                        color: 'olive',
                        size: 8
                      },
                      line: {
                        color: 'olive',
                        width: 2
                      },
                      name: 'Total'
                    }
                  ]}
                  layout={ {
                    width: '100%',
                    title: '',
                    xaxis: {
                      type: 'date',
                      autorange: true,
                      rangeslider: {range: [
                        moment.unix(Math.min(...profiles.map(p => p.timestamp))).format("YYYY-MM-DD"),
                        moment().format("YYYY-MM-DD")
                      ]}
                    }
                  } }
                  config={{displayModeBar: false}}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={4} textAlign="center" verticalAlign="middle">
                <Label color='purple' size='big'>Nombre de pointages</Label>
              </Grid.Column>
              <Grid.Column width={12} textAlign="center" verticalAlign="middle">
                <Plot
                  data={[
                    {
                      x: pointages.map(p => moment.unix(p.timestamp).format("YYYY-MM-DD HH:mm:ss")),
                      y: pointages.map(p => p.nbPointages),
                      type: 'scatter',
                      mode: 'lines+markers',
                      marker: {
                        color: 'purple',
                        size: 8
                      },
                      line: {
                        color: 'purple',
                        width: 2
                      },
                      name: 'Pointages standards'
                    },
                    {
                      x: pointages.map(p => moment.unix(p.timestamp).format("YYYY-MM-DD HH:mm:ss")),
                      y: pointages.map(p => p.nbRawPointages),
                      type: 'scatter',
                      mode: 'lines+markers',
                      marker: {
                        color: 'violet',
                        size: 8
                      },
                      line: {
                        color: 'violet',
                        width: 2,
                        dash: 'dashdot'
                      },
                      name: 'Pointages bruts'
                    }
                  ]}
                  layout={ {
                    width: '100%',
                    title: '',
                    xaxis: {
                      type: 'date',
                      autorange: true,
                      rangeslider: {range: [
                        moment.unix(Math.min(...pointages.map(p => p.timestamp))).format("YYYY-MM-DD"),
                        moment().format("YYYY-MM-DD")
                      ]}
                    }
                  } }
                  config={{displayModeBar: false}}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={4} textAlign="center" verticalAlign="middle">
                <Label color='red' size='big'>Modules auxiliaires</Label>
              </Grid.Column>
              <Grid.Column width={12} textAlign="center" verticalAlign="middle">
                <Plot
                  data={[
                    {
                      x: auxModules.map(am => moment.unix(am.timestamp).format("YYYY-MM-DD HH:mm:ss")),
                      y: auxModules.map(am => Object.keys(am.auxModules).length),
                      text: auxModules.map(am => Object.keys(am.auxModules).reduce((acc,m) => acc+`${AUX_MODULES_NAMES[m] || m} : ${am.auxModules[m].enabled ? "Oui" : "Non"} ; `, '')),
                      type: 'scatter',
                      mode: 'lines+markers',
                      marker: {
                        color: 'red',
                        size: 8
                      },
                      line: {
                        color: 'red',
                        width: 2
                      },
                      name: 'Modules auxiliaires actifs'
                    }
                  ]}
                  layout={ {
                    width: '100%',
                    title: '',
                    xaxis: {
                      type: 'date',
                      autorange: true,
                      rangeslider: {range: [
                        moment.unix(Math.min(...auxModules.map(am => am.timestamp))).format("YYYY-MM-DD"),
                        moment().format("YYYY-MM-DD")
                      ]}
                    }
                  } }
                  config={{displayModeBar: false}}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={4} textAlign="center" verticalAlign="middle">
                <Label color='yellow' size='big'>Statut réseau des badgeuses</Label>
              </Grid.Column>
              <Grid.Column width={12} textAlign="center" verticalAlign="middle">
                <Plot
                data={
                  rpiHardware.map(rh => {
                    const randomColor = `rgb(${Math.round(Math.random()*255)},${Math.round(Math.random()*255)},${Math.round(Math.random()*255)})`
                    return {
                      x: rpiHardwareOnline.filter(rho => Object.keys(rho.isOnline).includes(rh)).map(rho => moment.unix(rho.timestamp).format("YYYY-MM-DD HH:mm:ss")),
                      y: rpiHardwareOnline.filter(rho => Object.keys(rho.isOnline).includes(rh)).map(rho => rho.isOnline[rh] === true ? 1 : 0),
                      text: rpiHardwareOnline.filter(rho => Object.keys(rho.isOnline).includes(rh)).map(rho => `${rh} : ` + (rho.isOnline[rh] === true ? 'En ligne' : 'Hors ligne')),
                      type: 'scatter',
                      mode: 'lines+markers',
                      marker: {
                        color: randomColor,
                        size: 8
                      },
                      line: {
                        color: randomColor,
                        width: 2
                      },
                      name: 'En ligne'
                    };
                  })
                }
                layout={ {
                  width: '100%',
                  title: '',
                  xaxis: {
                    type: 'date',
                    autorange: true,
                    rangeslider: {range: [
                      moment.unix(Math.min(...rpiHardwareOnline.map(rho => rho.timestamp))).format("YYYY-MM-DD"),
                      moment().format("YYYY-MM-DD")
                    ]}
                  }
                } }
                config={{displayModeBar: false}}
              />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={4} textAlign="center" verticalAlign="middle">
                <Label color='green' size='big'>Activation du mode 3x8</Label>
              </Grid.Column>
              <Grid.Column width={12} textAlign="center" verticalAlign="middle">
                <Plot
                data={
                  nightShiftEnabled.map(nse => {
                    const randomColor = `rgb(${Math.round(Math.random()*255)},${Math.round(Math.random()*255)},${Math.round(Math.random()*255)})`
                    return {
                      x: moment.unix(nse.timestamp).format("YYYY-MM-DD HH:mm:ss"),
                      y: nse.enabled ? 1 : 0,
                      text: nse.enabled ? 'Activé' : 'Désactivé',
                      type: 'scatter',
                      mode: 'lines+markers',
                      marker: {
                        color: randomColor,
                        size: 8
                      },
                      line: {
                        color: randomColor,
                        width: 2
                      },
                      name: 'Activation 3x8'
                    };
                  })
                }
                layout={ {
                  width: '100%',
                  title: '',
                  xaxis: {
                    type: 'date',
                    autorange: true,
                    rangeslider: {range: [
                      moment.unix(Math.min(...rpiHardwareOnline.map(rho => rho.timestamp))).format("YYYY-MM-DD"),
                      moment().format("YYYY-MM-DD")
                    ]}
                  }
                } }
                config={{displayModeBar: false}}
              />
              </Grid.Column>
            </Grid.Row>
          </Grid>
      );
    } else {
      return;
    }
  }
}

export default CustomerDetail;
