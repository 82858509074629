import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import {
  Button,
  Container,
  Dimmer,
  Dropdown,
  Grid,
  Header,
  Icon,
  Input,
  Label,
  Loader,
  Message,
  Table
} from "semantic-ui-react";
import resolvePath from 'object-resolve-path';

class PhoneHardwares extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneHardwares: [],
      loading: true,
      selectedPhoneFilter: [],
      selectedHardwareFilter: [],
      selectedStatusFilter: [],
      selectedCommercialStatusFilter: [],
      selectedSimNumberFilter: [],
      sortState: {
        direction: 'ascending',
        column: 'imei'
      },
      error: undefined,
      editingPhoneHardware: undefined,
      megaFilter: ""
    };
  }

  componentDidMount() {
    this.getPhoneHardwares();
  }

  getPhoneHardwares() {
    return this.props.rest().get('phonehardwares').then((phoneHardwares) => {
      this.setState({
        phoneHardwares: phoneHardwares.sort((x,y) => x.imei < y.imei ? -1 : 1)
      });
    }).catch((e) => {
      this.manageErrors(e);
    }).finally(() => {
      this.setState({loading: false});
    });
  }

  manageErrors(err) {
    if(err !== undefined) {
      this.setState({
        error: err.entity
      });
      setTimeout(() => {
        this.setState({
          error: undefined
        });
      }, 5000);
    }
  }

  onClickOnAddPhoneHardware(event) {
    const phoneH = {
      imei: '- Nouvelle nomade',
      hardware: 'WAVE',
      simNumber: '',
      misc: '',
      status: 'IN_STOCK',
      commercialStatus: 'RENT'
    };
    this.props.rest().post('phonehardwares', phoneH).then(result => {
      this.setState({
        editingPhoneHardware: undefined
      }, () => {
        this.getPhoneHardwares();
      });
    })
    .catch(e => this.manageErrors(e));
  }

  onClickOnUpdPhoneHardware(pH) {
    return (event) => {
      this.props.rest().put('phonehardwares', pH).then(pH => {
        this.setState({
          editingPhoneHardware: undefined,
          phoneHardwares: [],
          loading: true
        }, () => {
          this.getPhoneHardwares();
        });
      }).catch(e => this.manageErrors(e));
    };
  }

  onChangePhoneHardwareProp(propName) {
    return (event, data) => {
      this.setState({
        editingPhoneHardware: Object.assign({}, this.state.editingPhoneHardware, {
          [propName]: event.target.value
        })
      });
    }
  }

  onChangePhoneHardwarePropEnum(propName) {
    return (e,data) => {
      this.setState({
        editingPhoneHardware: Object.assign({}, this.state.editingPhoneHardware, {
          [propName]: data.value
        })
      });
    }
  }

  handleSort(clickedColumn) {
    return () => {
      const { column, direction } = this.state.sortState;
      const data = this.state.phoneHardwares;

      if (column !== clickedColumn) {
        this.setState({
          sortState: Object.assign(this.state.sortState, {
            column: clickedColumn,
            direction: 'ascending'
          }),
          phoneHardwares: data.sort((d1,d2) => resolvePath(d1, clickedColumn) < resolvePath(d2, clickedColumn) ? -1 : 1)
        });
        return;
      }

      this.setState({
        sortState: Object.assign(this.state.sortState, {
          data: data.reverse(),
          direction: direction === 'ascending' ? 'descending' : 'ascending',
          column
        })
      });
    }
  }

  onChangePhoneHardwareFilter(filterProp) {
    return (e,data) => {
      this.setState({
        [filterProp]: data.value
      });
    }
  }

  render() {
    const statuses = [
      {
        key: "IN_STOCK",
        text: "En stock",
        value: "IN_STOCK"
      },
      {
        key: "DISMANTLED",
        text: "Démantelée",
        value: "DISMANTLED"
      },
      {
        key: "SAV",
        text: "En SAV",
        value: "SAV"
      },
      {
        key: "OTHER",
        text: "Autre",
        value: "OTHER"
      }
    ];

    const commercialStatuses = [
      {
        key: "RENT",
        text: "Loué",
        value: "RENT"
      },
      {
        key: "SOLD",
        text: "Vendu",
        value: "SOLD"
      }
    ];

    const hardwares = [
      {
        key: "SMOOTH",
        text: "Le Smooth",
        value: "SMOOTH"
      },
      {
        key: "OLA",
        text: "Le Ola",
        value: "OLA"
      },
      {
        key: "WAVE",
        text: "Le Wave",
        value: "WAVE"
      }
    ];

    const filteredPhoneHardwares = this.state.phoneHardwares
    .filter(x => this.state.selectedPhoneFilter.length > 0 ? this.state.selectedPhoneFilter.includes(x.id) : true)
    .filter(x => this.state.selectedHardwareFilter.length > 0 ? this.state.selectedHardwareFilter.includes(x.hardware) : true)
    .filter(x => this.state.selectedStatusFilter.length > 0 ? this.state.selectedStatusFilter.includes(x.status) : true)
    .filter(x => this.state.selectedCommercialStatusFilter.length > 0 ? this.state.selectedCommercialStatusFilter.includes(x.commercialStatus) : true)
    .filter(x => this.state.selectedSimNumberFilter.length > 0 ? this.state.selectedSimNumberFilter.includes(x.id) : true)
    .filter(c => this.state.megaFilter.length > 0 ?
      c.imei.toLowerCase().includes(this.state.megaFilter.toLowerCase()) ||
      c.simNumber.toLowerCase().includes(this.state.megaFilter.toLowerCase()) ||
      c.misc.toLowerCase().includes(this.state.megaFilter.toLowerCase())
    : true);

    return (
        <Container className="adminContentClass" textAlign="center">
            <Grid padded>
              <Grid.Row>
                {
                  this.state.error !== undefined ?
                    <Message negative content={this.state.error} />
                    : ""
                }
              </Grid.Row>
              <Grid.Row style={{
                  backgroundColor: '#545454',
                  borderRadius: '10px'
                }}>
                <Grid.Column width={2}>
                  <Icon name='mobile alternate' size='huge' inverted color='blue' circular />
                </Grid.Column>
                <Grid.Column width={1}></Grid.Column>
                <Grid.Column width={10} verticalAlign="middle" textAlign="center">
                  <Header size="huge" as="h1" inverted>
                    <Header.Content>
                      Liste des badgeuses nomades
                      <Header.Subheader>
                        Retrouvez ici la liste des badgeuses nomades
                      </Header.Subheader>
                    </Header.Content>
                  </Header>
                </Grid.Column>
                <Grid.Column width={2}></Grid.Column>
                <Grid.Column width={1} verticalAlign="middle">
                  <Button data-tooltip="Ajouter une badgeuse nomade" data-position="bottom right" icon color='green' onClick={this.onClickOnAddPhoneHardware.bind(this)}>
                    <Icon name='plus' />
                  </Button>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Input
                    placeholder='Recherche libre'
                    icon='search'
                    fluid
                    onChange={(evt) => this.setState({megaFilter: evt.target.value})}
                    value={this.state.megaFilter}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8}>
                  <Dropdown
                    placeholder='Toutes les badgeuses nomades'
                    search
                    fluid
                    selection
                    multiple
                    clearable
                    onChange={this.onChangePhoneHardwareFilter('selectedPhoneFilter').bind(this)}
                    value={this.state.selectedPhoneFilter}
                    options={this.state.phoneHardwares.map((x) => ({key: x.id, text: x.imei, value: x.id}))}
                  />
                </Grid.Column>
                <Grid.Column width={8}>
                  <Dropdown
                    placeholder='Tous les numéros de SIM'
                    search
                    fluid
                    selection
                    multiple
                    clearable
                    onChange={this.onChangePhoneHardwareFilter('selectedSimNumberFilter').bind(this)}
                    value={this.state.selectedSimNumberFilter}
                    options={this.state.phoneHardwares.filter(x => x.simNumber !== null && x.simNumber !== undefined && x.simNumber.length > 0)
                      .map((x, idx) => ({
                        key: x.id,
                        text: x.simNumber,
                        value: x.id
                    }))}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={6}>
                  <Dropdown
                    placeholder='Tous les modèles'
                    search
                    fluid
                    selection
                    multiple
                    clearable
                    onChange={this.onChangePhoneHardwareFilter('selectedHardwareFilter').bind(this)}
                    value={this.state.selectedHardwareFilter}
                    options={hardwares}
                  />
                </Grid.Column>
                <Grid.Column width={6}>
                  <Dropdown
                    placeholder='Tous les statuts'
                    search
                    fluid
                    selection
                    multiple
                    clearable
                    onChange={this.onChangePhoneHardwareFilter('selectedStatusFilter').bind(this)}
                    value={this.state.selectedStatusFilter}
                    options={statuses}
                  />
                </Grid.Column>
                <Grid.Column width={4}>
                  <Dropdown
                    placeholder='Tous les statuts commerciaux'
                    search
                    fluid
                    selection
                    multiple
                    clearable
                    onChange={this.onChangePhoneHardwareFilter('selectedCommercialStatusFilter').bind(this)}
                    value={this.state.selectedCommercialStatusFilter}
                    options={commercialStatuses}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column style={{textAlign: 'left'}} width={2}>
                  <Label size='large'>{filteredPhoneHardwares.length} ligne(s) trouvée(s)</Label>
                </Grid.Column>
                <Grid.Column width={14}></Grid.Column>
              </Grid.Row>
              <Grid.Row style={{minHeight: '30vh'}}>
                {
                  this.state.loading === true ?
                  <Dimmer inverted active>
                    <Loader inverted />
                  </Dimmer>                
                  :
                  <Table striped selectable sortable>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell
                        sorted={this.state.sortState.column === 'imei' ? this.state.sortState.direction : null}
                        onClick={this.handleSort('imei').bind(this)}>
                          IMEI
                        </Table.HeaderCell>
                        <Table.HeaderCell
                        sorted={this.state.sortState.column === 'hardware' ? this.state.sortState.direction : null}
                        onClick={this.handleSort('hardware').bind(this)}>
                          Modèle
                        </Table.HeaderCell>
                        <Table.HeaderCell
                        sorted={this.state.sortState.column === 'simNumber' ? this.state.sortState.direction : null}
                        onClick={this.handleSort('simNumber').bind(this)}>
                          Numéro de sim
                        </Table.HeaderCell>
                        <Table.HeaderCell>Notes</Table.HeaderCell>
                        <Table.HeaderCell
                        sorted={this.state.sortState.column === 'status' ? this.state.sortState.direction : null}
                        onClick={this.handleSort('status').bind(this)}>
                          Statut
                        </Table.HeaderCell>
                        <Table.HeaderCell
                        sorted={this.state.sortState.column === 'commercialStatus' ? this.state.sortState.direction : null}
                        onClick={this.handleSort('commercialStatus').bind(this)}>
                          Statut commercial
                        </Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {
                        filteredPhoneHardwares
                        .map((pH, idx) => this.state.editingPhoneHardware !== undefined && this.state.editingPhoneHardware.id === pH.id ? (
                          <Table.Row key={`details_phonehardware_${idx}`}>
                            <Table.Cell width={1}>
                              <Input fluid disabled={pH.status === 'PRODUCTION'} onChange={this.onChangePhoneHardwareProp('imei').bind(this)} value={this.state.editingPhoneHardware.imei} />
                            </Table.Cell>
                            <Table.Cell width={3}>
                              <Dropdown
                                placeholder='Modèle...'
                                search
                                fluid
                                selection
                                onChange={this.onChangePhoneHardwarePropEnum('hardware').bind(this)}
                                value={this.state.editingPhoneHardware.hardware}
                                options={hardwares}
                              />
                            </Table.Cell>
                            <Table.Cell width={2}>
                              <Input fluid onChange={this.onChangePhoneHardwareProp('simNumber').bind(this)} value={this.state.editingPhoneHardware.simNumber} />
                            </Table.Cell>
                            <Table.Cell width={3}>
                              <Input fluid onChange={this.onChangePhoneHardwareProp('misc').bind(this)} value={this.state.editingPhoneHardware.misc} />
                            </Table.Cell>
                            <Table.Cell width={1}>
                              {
                                pH.status === 'PRODUCTION' ? 
                                  <Dropdown
                                    fluid
                                    selection
                                    disabled
                                    value='PRODUCTION'
                                    options={[{
                                      key: 'PRODUCTION',
                                      text: 'En prod',
                                      value: 'PRODUCTION'
                                    }]}
                                  /> :
                                  <Dropdown
                                    placeholder='Statut...'
                                    search
                                    fluid
                                    selection
                                    onChange={this.onChangePhoneHardwarePropEnum('status').bind(this)}
                                    value={this.state.editingPhoneHardware.status}
                                    options={statuses}
                                  />
                              }
                            </Table.Cell>
                            <Table.Cell>
                              <Dropdown
                                placeholder='Vendu'
                                search
                                fluid
                                selection
                                onChange={this.onChangePhoneHardwarePropEnum('commercialStatus').bind(this)}
                                value={this.state.editingPhoneHardware.commercialStatus}
                                options={commercialStatuses}
                              />
                            </Table.Cell>
                            <Table.Cell width={1}>
                              <Button color='green' onClick={this.onClickOnUpdPhoneHardware(this.state.editingPhoneHardware).bind(this)}><Icon name='check' /></Button>
                            </Table.Cell>
                          </Table.Row>
                        ) : (
                          <Table.Row onClick={() => this.setState({editingPhoneHardware: pH})} key={`details_phonehardware_${idx}`}>
                            <Table.Cell width={1}>{pH.imei}</Table.Cell>
                            <Table.Cell width={1}>{hardwares.map(x => x.value).includes(pH.hardware) ? hardwares.find(x => x.value === pH.hardware).text : pH.hardware}</Table.Cell>
                            <Table.Cell width={2}>{pH.simNumber}</Table.Cell>
                            <Table.Cell width={3}>{pH.misc}</Table.Cell>
                            <Table.Cell width={1}>{pH.status === 'PRODUCTION' ? 'En prod' : statuses.map(x => x.value).includes(pH.status) ? statuses.find(x => x.value === pH.status).text : pH.status}</Table.Cell>
                            <Table.Cell>{commercialStatuses.map(x => x.value).includes(pH.commercialStatus) ? commercialStatuses.find(x => x.value === pH.commercialStatus).text : pH.commercialStatus}</Table.Cell>
                            <Table.Cell width={1}><Button color='green'><Icon name='check' disabled /></Button></Table.Cell>
                          </Table.Row>
                        ))
                      }
                    </Table.Body>
                  </Table>
                }
              </Grid.Row>
            </Grid>
        </Container>
    );
  }
}

export default PhoneHardwares;
