import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import {
  Button,
  Checkbox,
  Container,
  Dimmer,
  Dropdown,
  Grid,
  Header,
  Icon,
  Input,
  Label,
  Loader,
  Modal,
  Table,
  Popup
} from "semantic-ui-react";
import resolvePath from 'object-resolve-path';
import randomstring from 'randomstring';
import moment from 'moment';

class Customers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customers: [],
      dbStatuses: [],
      vpnClientList: [],
      availableRpiHardwares: [],
      prodRpiHardwares: [],
      traccarGroups: [],
      editingCustomer: {
        rpiHardware: {},
        phoneHardware: {},
        auxModules: {}
      },
      error: undefined,
      sortState: {
        data: []
      },
      loading: false,
      visibleNotes: false,
      enable38Modal: false,
      welcomeEmailLoading: false,
      welcomeEmailResult: undefined,
      welcomeEmailPopup: false,
      welcomeEmailHeader: "Madame, Monsieur,",
      welcomeEmailRecipients: "",
      megaFilter: ""
    };
  }

  componentDidMount() {
    this.getCustomers();
  }

  getCustomers() {
    return new Promise((resolve, reject) => {
        this.setState({loading: true}, () => {
          resolve();
        })
    }).then(() => Promise.all([
        this.props.rest().get('clients'),
        this.props.rest().get('database'),
        this.props.rest().get('openvpn/clientList'),
        this.props.rest().get('rpihardwares'),
        this.props.rest().get('phoneHardwares'),
        this.props.rest().get('traccar/groups')
    ]).then(([customers,dbStatuses,vpnClientList,rpiHardwares,phoneHardwares,traccarGroups]) => {
        this.setState({
          customers,
          dbStatuses,
          vpnClientList,
          availableRpiHardwares: rpiHardwares.filter(x => x.status === 'IN_STOCK'),
          prodRpiHardwares: rpiHardwares.filter(x => x.status === 'PRODUCTION'),
          availablePhoneHardwares: phoneHardwares.filter(x => x.status === 'IN_STOCK'),
          prodPhoneHardwares: phoneHardwares.filter(x => x.status === 'PRODUCTION'),
          traccarGroups,
          sortState: Object.assign({}, this.state.sortState, {
            data: customers
          })
        });
    })).catch((e) => {
      this.manageErrors(e);
    }).finally(() => {
      this.setState({loading: false});
    });
  }

  manageErrors(err) {
    if(err !== undefined) {
      this.setState({
        error: err.entity
      });
      setTimeout(() => {
        this.setState({
          error: ""
        });
      }, 5000);
    }
  }

  getRandomString() {
    let rdStr = '';
    for(let i = 0; i<=3; i++) {
      rdStr += Math.random().toString(36).substring(2, 15);
    }
    return rdStr;
  }

  onClickOnCustomerRow(customer) {
    return (event) => {
      if(customer.id !== this.state.editingCustomer.id) {
        this.setState({
          editingCustomer: customer
        });
      }
    };
  }

  onClickOnAddCustomer(event) {
    this.props.rest().post('clients', {
      name: '- Nouveau Client',
      code: randomstring.generate({
        length: 8,
        charset: 'numeric'
      }),
      email: '',
      password: randomstring.generate({
        length: 8,
        charset: 'alphanumeric'
      }),
      accessToken: this.getRandomString(),
      auxModules: {},
      rpiHardware: {},
      phoneHardware: {},
      traccarGroupId: 0,
      nightShiftEnabled: false,
      notes: ""
    }).then(customer => 
      this.props.rest().post('database', {id: customer.id})
        .then(result => 
          this.getCustomers().then(() => {
            this.setState({
              editingCustomer: Object.assign({}, customer, {rpiHardware: {}, phoneHardware: {}, auxModules: {}})
            });
          })
        )
    )
    .catch(e => this.manageErrors(e));
  }

  onClickOnUpdCustomer(customer) {
    return (event) => {
      this.props.rest().put('clients', customer).then(customer => {
        this.setState({
          editingCustomer: {rpiHardware: {}, phoneHardware: {}, auxModules: {}}
        }, () => {
          this.getCustomers();
        });
      }).catch(e => this.manageErrors(e));
    };
  }

  onClickOnConnectToCustomerAccount(customerToken) {
    return (event) => {
      window.open(`https://orane.online/login?token=${customerToken}`, '_blank').focus();
    };
  }

  /*onClickOnDelCustomer(id) {
    return (event) => {
      delete('clients', {id}).then(result => {
        this.getCustomers();
      }).catch(e => this.manageErrors(e));
    };
  }*/

  onChangeCustomerProp(propName) {
    return (event, data) => {
      let usefulData = event.target.value;
      if(usefulData === undefined && data !== undefined) {
        usefulData = data.value;
      }
      this.setState({
        editingCustomer: Object.assign({}, this.state.editingCustomer, {[propName]: usefulData})
      });
    };
  }

  onChangeTraccarGroupId(e,data) {
    this.setState({
      editingCustomer: Object.assign({}, this.state.editingCustomer, {
        traccarGroupId: data.value
      })
    });
  }

  onChangeRpiHardwares(e,data) {
    this.setState({
      editingCustomer: Object.assign({}, this.state.editingCustomer, {
        rpiHardware: data.value.reduce((acc, x) => {
          const foundHardware = [...this.state.availableRpiHardwares, ...this.state.prodRpiHardwares].find((y) => y.name === x);
          if(foundHardware !== undefined) {
            return Object.assign(acc, {
              [x]: foundHardware.token
            });
          } else {
            return acc;
          }
        }, {})
      })
    });
  }

  onChangePhoneHardwares(e,data) {
    const oldPhoneHardware = this.state.editingCustomer.phoneHardware;
    const newPhoneHardware = data.value.reduce((acc, imei) => {
      const oldImeis = Object.keys(oldPhoneHardware);
      if(oldImeis.includes(imei)) {
        return Object.assign(acc, {[imei]: oldPhoneHardware[imei]});
      } else {
        const foundPhone = [...this.state.availablePhoneHardwares, ...this.state.prodPhoneHardwares].find((y) => y.imei === imei);
        if(foundPhone !== undefined) {
          return Object.assign(acc, {[imei]: {customName: foundPhone.customName || "", simNumber: foundPhone.simNumber || ""}});
        } else {
          return acc;
        }
      }
    }, {});
    this.setState({
      editingCustomer: Object.assign({}, this.state.editingCustomer, {
        phoneHardware: newPhoneHardware
      })
    });
  }

  handleSort(clickedColumn) {
    return () => {
      const { column, data, direction } = this.state.sortState;

      if (column !== clickedColumn) {
        this.setState({
          sortState: Object.assign(this.state.sortState, {
            column: clickedColumn,
            data: data.sort((d1,d2) => resolvePath(d1, clickedColumn) < resolvePath(d2, clickedColumn) ? -1 : 1),
            direction: 'ascending'
          })
        });
        return;
      }

      this.setState({
        sortState: Object.assign(this.state.sortState, {
          data: data.reverse(),
          direction: direction === 'ascending' ? 'descending' : 'ascending',
          column
        })
      });
    }
  }

  onClickOnEnable38Mode(customerId) {
    return (evt) => {
      const customer = this.state.customers.find(x => x.id === customerId);
      if(customer !== undefined) {
        customer.nightShiftEnabled = !customer.nightShiftEnabled
        this.props.rest().put('clients', customer).then(customer => {
          this.setState({
            customers: this.state.customers.map(x => x.id !== customer.id ? x : customer)
          });
        }).catch(e => this.manageErrors(e)).finally(() => this.setState({enable38Modal: false}));
      }
    };
  }

  /*
  toggleAuxModule(moduleName) {
    return (event) => {
      let newAuxModules = Object.assign({}, this.state.editingCustomer.auxModules);
      newAuxModules[moduleName] = (newAuxModules[moduleName] === undefined ? true : !newAuxModules[moduleName])
      this.setState({
        editingCustomer: Object.assign({}, this.state.editingCustomer, {
          auxModules: newAuxModules
        })
      });
    };
  }
  */

  onClickOnSendWelcomeEmail(customerId) {
    return (evt) => {
      this.setState({welcomeEmailLoading: customerId, welcomeEmailPopup: false}, () => {
        this.props.rest().post('database/sendWelcomeEmail', {id: customerId, header: this.state.welcomeEmailHeader, recipients: this.state.welcomeEmailRecipients})
        .then((result) => {
          const customer = this.state.customers.find((x) => x.id === customerId);
          this.setState({
            welcomeEmailResult: customerId,
            customers: [...this.state.customers.filter(x => x.id !== customerId), Object.assign({}, customer, {
              welcomeEmailsHistory: [...customer.welcomeEmailsHistory, ({header: this.state.welcomeEmailHeader, recipients: this.state.welcomeEmailRecipients, timestamp: moment().unix()})]
            })]
          });
        }).catch((err) => {
          this.setState({welcomeEmailResult: false});
        }).finally(() => this.setState({welcomeEmailLoading: false, welcomeEmailHeader: 'Madame, Monsieur,', welcomeEmailRecipients: ''}))
      })
    };
  }

  render() {
    const customerTypes = [['ACTIVE', 'Actif'], ['LOCKED', 'Verrouillé'], ['DEMO', 'Démo'], ['TERMINATED', 'Résilié']];

    const filteredCustomers = this.state.customers
    .filter(c => this.state.megaFilter.length > 0 ?
      c.id.toString().toLowerCase().includes(this.state.megaFilter.toLowerCase()) ||
      c.name.toLowerCase().includes(this.state.megaFilter.toLowerCase()) ||
      c.email.toLowerCase().includes(this.state.megaFilter.toLowerCase()) ||
      c.code.toLowerCase().includes(this.state.megaFilter.toLowerCase()) ||
      c.notes.toLowerCase().includes(this.state.megaFilter.toLowerCase()) ||
      Object.keys(c.rpiHardware).map(k => k.toLowerCase()).reduce((acc, x) => `${acc}${x} `, "").includes(this.state.megaFilter.toLowerCase()) ||
      Object.keys(c.phoneHardware).map(k => k.toLowerCase()).reduce((acc, x) => `${acc}${x} `, "").includes(this.state.megaFilter.toLowerCase()) ||
      Object.keys(c.phoneHardware).map(k => (c.phoneHardware[k].imei || "").toLowerCase()).reduce((acc, x) => `${acc}${x} `, "").includes(this.state.megaFilter.toLowerCase()) ||
      Object.keys(c.phoneHardware).map(k => (c.phoneHardware[k].simNumber || "").toLowerCase()).reduce((acc, x) => `${acc}${x} `, "").includes(this.state.megaFilter.toLowerCase())
    : true);

    return (
        <Container className="adminContentClass" textAlign="center">
          {
            <Grid padded>
              <Grid.Row style={{
                  backgroundColor: '#545454',
                  borderRadius: '10px'
                }}>
                <Grid.Column width={2}>
                  <Icon name='database' size='huge' inverted color='blue' circular />
                </Grid.Column>
                <Grid.Column width={1}></Grid.Column>
                <Grid.Column width={10} verticalAlign="middle" textAlign="center">
                  <Header size="huge" as="h1" inverted>
                    <Header.Content>
                      Configuration des comptes clients
                      <Header.Subheader>
                        Attention à ce que vous faites ! Ne modifiez rien sans comprendre.
                      </Header.Subheader>
                      <br />
                      <Header.Subheader>
                        <Checkbox style={{verticalAlign: 'middle'}} toggle checked={this.state.visibleNotes} onChange={() => {
                          this.setState({visibleNotes: !this.state.visibleNotes});
                        }} /><Label>Afficher également les notes</Label>
                      </Header.Subheader>
                    </Header.Content>  
                  </Header>
                </Grid.Column>
                <Grid.Column width={2}></Grid.Column>
                <Grid.Column width={1} verticalAlign="middle">
                  <Button data-tooltip="Ajouter un client" data-position="bottom right" icon color='green' onClick={this.onClickOnAddCustomer.bind(this)}>
                    <Icon name='plus' />
                  </Button>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                {this.state.error}
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Input
                    placeholder='Recherche libre'
                    icon='search'
                    fluid
                    onChange={(evt) => this.setState({megaFilter: evt.target.value})}
                    value={this.state.megaFilter}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column style={{textAlign: 'left'}} width={2}>
                  <Label size='large'>{filteredCustomers.length} ligne(s) trouvée(s)</Label>
                </Grid.Column>
                <Grid.Column width={14}></Grid.Column>
              </Grid.Row>
              <Grid.Row style={{marginBottom: '100px'}}>
                {
                this.state.loading === true ?
                <Dimmer inverted active>
                  <Loader inverted />
                </Dimmer>
                :
                <Table striped selectable sortable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell
                        sorted={this.state.sortState.column === 'id' ? this.state.sortState.direction : null}
                        onClick={this.handleSort('id').bind(this)}>
                        ID
                      </Table.HeaderCell>
                      <Table.HeaderCell
                      sorted={this.state.sortState.column === 'name' ? this.state.sortState.direction : null}
                      onClick={this.handleSort('name').bind(this)}>
                        Nom
                      </Table.HeaderCell>
                      <Table.HeaderCell
                      sorted={this.state.sortState.column === 'code' ? this.state.sortState.direction : null}
                      onClick={this.handleSort('code').bind(this)}>
                        Code
                      </Table.HeaderCell>
                      <Table.HeaderCell
                      sorted={this.state.sortState.column === 'email' ? this.state.sortState.direction : null}
                      onClick={this.handleSort('email').bind(this)}>
                        Email
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        MDP
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        Token
                      </Table.HeaderCell>
                      <Table.HeaderCell>Pointeuses</Table.HeaderCell>
                      <Table.HeaderCell>Téléphones</Table.HeaderCell>
                      <Table.HeaderCell>Applis.</Table.HeaderCell>
                      <Table.HeaderCell>Groupe Traccar</Table.HeaderCell>
                      <Table.HeaderCell>Type</Table.HeaderCell>
                      <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {
                      filteredCustomers
                      .map(c => (
                        [<Table.Row key={`details_customer_${c.id}`} onClick={this.onClickOnCustomerRow(c).bind(this)}>
                          <Table.Cell>{c.id}</Table.Cell>
                          <Table.Cell>
                            {
                            this.state.editingCustomer.id === c.id ?
                            <Input fluid placeholder='Nom...' value={this.state.editingCustomer.name} onChange={this.onChangeCustomerProp('name').bind(this)} />
                            : c.name
                            }
                          </Table.Cell>
                          <Table.Cell>
                            {
                            this.state.editingCustomer.id === c.id ?
                            <Input fluid placeholder='Code...' value={this.state.editingCustomer.code} onChange={this.onChangeCustomerProp('code').bind(this)} />
                            : c.code
                            }
                          </Table.Cell>
                          <Table.Cell>
                            {
                            this.state.editingCustomer.id === c.id ?
                            <Input fluid placeholder='Email...' value={this.state.editingCustomer.email} onChange={this.onChangeCustomerProp('email').bind(this)} />
                            : c.email
                            }
                          </Table.Cell>
                          <Table.Cell>
                            {
                            this.state.editingCustomer.id === c.id ?
                            <Input type="password" fluid placeholder='Mot de passe...' value={this.state.editingCustomer.password} onChange={this.onChangeCustomerProp('password').bind(this)} />
                            : "(masqué)"
                            }
                          </Table.Cell>
                          <Table.Cell>
                            {
                            this.state.editingCustomer.id === c.id ?
                            <Input fluid placeholder='Token...' value={this.state.editingCustomer.accessToken} onChange={this.onChangeCustomerProp('accessToken').bind(this)} />
                            : "(masqué)"
                            }
                          </Table.Cell>
                          <Table.Cell>
                            {
                            this.state.editingCustomer.id === c.id ?
                              <Dropdown
                                placeholder='BMO-XXXX-YYYY'
                                search
                                fluid
                                selection
                                multiple
                                onChange={this.onChangeRpiHardwares.bind(this)}
                                value={Object.keys(this.state.editingCustomer.rpiHardware)}
                                options={[...this.state.availableRpiHardwares.map(x => x.name), ...Object.keys(this.state.editingCustomer.rpiHardware)].map(x => ({
                                  key: x,
                                  text: x,
                                  value: x
                                }))}
                              /> :
                              Object.keys(c.rpiHardware).map((rh, idx) =>
                                <Label data-tooltip={this.state.vpnClientList.includes(rh) ? "En ligne" : "Hors ligne"} data-position="bottom right" color={this.state.vpnClientList.includes(rh) ? "green" : "red"} key={`rpi_${c.id}_${idx}`} size='tiny' as='span'>{rh}</Label>
                              )
                            }
                          </Table.Cell>
                          <Table.Cell>
                            {
                              this.state.editingCustomer.id === c.id ?
                                <Dropdown
                                  placeholder='3142XXXXXXX'
                                  search
                                  fluid
                                  selection
                                  multiple
                                  onChange={this.onChangePhoneHardwares.bind(this)}
                                  value={Object.keys(this.state.editingCustomer.phoneHardware)}
                                  options={[...this.state.availablePhoneHardwares.map(x => x.imei), ...Object.keys(this.state.editingCustomer.phoneHardware)].map(x => ({
                                    key: x,
                                    text: x,
                                    value: x
                                  }))}
                                /> :
                                Object.keys(c.phoneHardware).map((ph, idx) =>
                                  <Label data-tooltip={c.phoneHardware[ph].simNumber && c.phoneHardware[ph].simNumber !== "" ? `Sim : ${c.phoneHardware[ph].simNumber}` : "Pas de numéro de sim renseigné"} data-position="bottom right" key={`phone_${c.id}_${idx}`} size='tiny' as='span'>{ph}</Label>
                                )
                            }
                          </Table.Cell>
                          <Table.Cell>
                            {
                            this.state.editingCustomer.id === c.id ?
                            <Input fluid placeholder='Nombre...' value={this.state.editingCustomer.phoneSoftware} onChange={this.onChangeCustomerProp('phoneSoftware').bind(this)} />
                            : c.phoneSoftware
                            }
                          </Table.Cell>
                          <Table.Cell>
                            {
                              this.state.editingCustomer.id === c.id ?
                                <Dropdown
                                  placeholder='ID de groupe'
                                  search
                                  fluid
                                  selection
                                  onChange={this.onChangeTraccarGroupId.bind(this)}
                                  value={this.state.editingCustomer.traccarGroupId}
                                  options={[{id: 0, name: 'Non assigné'}, ...this.state.traccarGroups].map(x => ({
                                    key: `traccarGroup_${x.id}`,
                                    text: x.name,
                                    value: x.id
                                  }))}
                                /> :
                                <Label size='tiny' as='span'>{(this.state.traccarGroups.find(x => x.id === c.traccarGroupId) || {name: "Non assigné"}).name}</Label>
                            }
                          </Table.Cell>
                          <Table.Cell>
                            {
                              this.state.editingCustomer.id === c.id ?
                                <Dropdown
                                  placeholder='Type'
                                  search
                                  fluid
                                  selection
                                  onChange={this.onChangeCustomerProp('type').bind(this)}
                                  value={this.state.editingCustomer.type}
                                  options={customerTypes.map(x => (
                                    {
                                      key: x[0],
                                      text: x[1],
                                      value: x[0]
                                    }
                                  ))}
                                />
                              : (customerTypes.find(x => x[0] === c.type) !== undefined ? customerTypes.find(x => x[0] === c.type)[1] : c.type)
                            }
                          </Table.Cell>
                          <Table.Cell width={2}>
                            <Button data-tooltip="Enregistrer le client" data-position="bottom right" icon color='green' disabled={this.state.editingCustomer.id !== c.id} onClick={this.onClickOnUpdCustomer(this.state.editingCustomer).bind(this)}>
                              <Icon name='check' />
                            </Button>
                            <Popup
                              content={
                                <span>
                                  <Input label={<Label>Destinataires séparés par des ;</Label>} onChange={(e) => this.setState({welcomeEmailRecipients: e.target.value || c.email})} fluid placeholder="Destinataires séparés par des ;" value={this.state.welcomeEmailRecipients || ""} />
                                  <Input label={<Label>Première ligne de l'email</Label>} onChange={(e) => this.setState({welcomeEmailHeader: e.target.value || "Madame, Monsieur,"})} fluid placeholder="Première ligne de l'email" value={this.state.welcomeEmailHeader || ""} />
                                  <Button.Group>
                                    <Button color='red' onClick={this.onClickOnSendWelcomeEmail(c.id).bind(this)}><Icon name='play' /></Button>,
                                    <Button icon='close' style={{float: 'right'}} onClick={() => this.setState({welcomeEmailPopup: false})} />
                                  </Button.Group>
                                </span>
                              }
                              open={this.state.welcomeEmailPopup === c.id}
                              flowing
                              position='bottom right'
                              header={<span>Entrez les paramètres souhaités ci-dessous puis appuyez sur le bouton rouge pour envoyer ou la croix pour annuler.</span>}
                              on='click'
                              pinned
                              onOpen={() => this.setState({welcomeEmailRecipients: c.email})}
                              trigger={
                                <span data-tooltip={this.state.welcomeEmailResult === undefined || this.state.welcomeEmailResult !== c.id ? `Envoyer le mail de bienvenue (envoyé ${c.welcomeEmailsHistory.length} fois, dernière fois : ${c.welcomeEmailsHistory.slice(-1)[0] ? 'le ' + moment.unix(c.welcomeEmailsHistory.slice(-1)[0].timestamp).format('DD/MM/YYYY à HH:mm:ss') : 'jamais'})` : this.state.welcomeEmailResult ? "L'email a bien été envoyé" : "Une erreur est survenue lors de l'envoi"} data-position="bottom right">
                                  <Button icon color={this.state.welcomeEmailResult === undefined || this.state.welcomeEmailResult !== c.id ? 'orange' : this.state.welcomeEmailResult ? 'green' : 'red'} loading={this.state.welcomeEmailLoading === c.id} onClick={() => this.setState({welcomeEmailPopup: c.id})}>
                                    <Icon name='mail' />&nbsp;&nbsp;&nbsp;{c.welcomeEmailsHistory.length}
                                  </Button>
                                </span>
                              }
                            />
                            <Button data-tooltip="Se connecter sur le compte du client" data-position="bottom right" icon color='blue' onClick={this.onClickOnConnectToCustomerAccount(c.accessToken).bind(this)}>
                              <Icon name='lock' />
                            </Button>
                            <Button data-tooltip={c.nightShiftEnabled === false ? "Activer le mode 3x8" : "Désactiver le mode 3x8"} data-position="bottom right" icon color={c.nightShiftEnabled === false ? 'red' : 'green'} onClick={() => this.setState({enable38Modal: c})}>
                              <Icon name='clock' />
                            </Button>
                            {
                              /*
                            <Button data-tooltip="Supprimer le client" disabled data-position="bottom right" icon color='red'>
                              <Icon name='trash' />
                            </Button>
                              */
                            }
                          </Table.Cell>
                        </Table.Row>,
                        this.state.visibleNotes === true ? <Table.Row key={`notes_customer_${c.id}`} onClick={this.onClickOnCustomerRow(c).bind(this)}>
                          <Table.Cell style={{textDecoration: 'underline'}}>Notes</Table.Cell>
                          <Table.Cell colSpan={10}>
                            {
                            this.state.editingCustomer.id === c.id ?
                            <Input fluid placeholder='Notes...' value={this.state.editingCustomer.notes} onChange={this.onChangeCustomerProp('notes').bind(this)} />
                            : c.notes || ""
                            }
                          </Table.Cell>
                          <Table.Cell></Table.Cell>
                        </Table.Row> : null
                      ]).flat().filter(x => x !== null))
                    }
                  </Table.Body>
                </Table>
                }
                <Modal size='small' open={this.state.enable38Modal !== false}>
                  <Modal.Header icon='list alternate outline' content={`${this.state.enable38Modal && this.state.enable38Modal.nightShiftEnabled ? "Désactivation" : "Activation"} du mode 3x8 pour ${this.state.enable38Modal && this.state.enable38Modal.code} - ${this.state.enable38Modal && this.state.enable38Modal.name}`} />
                  <Modal.Content>
                    Attention, cette option aura des conséquences sur les données des clients à compter de maintenant.<br />
                    Notamment, des sorties automatiques seront auto-générés chaque jour à minuit si vous activez l'option, ou ces générations cesseront si vous désactivez l'option.<br />
                    Êtes-vous sûr de vouloir effectuer cette action ?
                  </Modal.Content>
                  <Modal.Actions>
                    <Button basic color='red' onClick={this.onClickOnEnable38Mode(this.state.enable38Modal && this.state.enable38Modal.id).bind(this)}>
                      <Icon name='check' /> Oui, {this.state.enable38Modal && this.state.enable38Modal.nightShiftEnabled ? "désactiver" : "activer"} le mode 3x8
                    </Button>
                    <Button color='green' onClick={() => this.setState({enable38Modal: false})}>
                      <Icon name='close' /> Non, annuler l'opération
                    </Button>
                  </Modal.Actions>
                </Modal>
              </Grid.Row>
            </Grid>
          }
        </Container>
    );
  }
}

export default Customers;
